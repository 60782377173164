import { UploadedFile } from '../Views/Common/Forms/FileInput';

const MMS_TOTAL_SIZE_LIMIT_BYTES = 5242880;

export function useMmsMediaValidation() {
    const validateMmsMedias = (medias: UploadedFile[]) => {
        if ((medias?.length || 0) > 10) {
            return 'Maximum 10 files are allowed';
        }

        if (
            medias.reduce((acc, x) => (x.size || 0) + acc, 0) >
            MMS_TOTAL_SIZE_LIMIT_BYTES
        ) {
            return 'The 5 MB limit for the total size of the combined media has been reached';
        }

        return false;
    };

    return { validateMmsMedias };
}
